import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { Link } from "react-router-dom";
import {
  Button,
  TextField,
  Grid,
  AppBar,
  Typography,
  Toolbar,
} from "@material-ui/core";

const BRAND_NAME = "Mercado Escolar";

const useStyles = makeStyles({
  table: {
    width: "80%",
    margin: "auto",
  },
});

function createData(ChatID, StudentID, Message, Reply, Submit) {
  return { ChatID, StudentID, Message, Reply, Submit };
}

const rows = [
  createData("Ch323", "S001", "package was damaged when it arrived", "yes"),
  createData("Ch223", "S004", "Recieved wrong Products", "Yes"),
  createData("P121", "Book", "Good Condition", " $20", "yes"),
  createData("CH123", "S002", "Haven't got the refund yet", "Yes"),
];

export default function Chat() {
  const classes = useStyles();

  return (
    <div>
      <AppBar position="static" alignitems="center" color="primary">
        <Toolbar>
          <Grid container justify="center" wrap="wrap">
            <Grid item>
              <Typography variant="h6">{BRAND_NAME}</Typography>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <Link to="/OwnerHome" className={classes.link}>
        Home
      </Link>
      <TableContainer component={Paper}>
        <Table aria-label="simple table" className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell align="right">Chat ID</TableCell>
              <TableCell align="right">Student ID</TableCell>
              <TableCell align="right">Message</TableCell>
              <TableCell align="right">Reply</TableCell>
              <TableCell align="right">Submit?</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow key={row.number}>
                {/* <TableCell component="th" scope="row">
                {row.number}
              </TableCell> */}
                <TableCell align="right">{row.ChatID}</TableCell>
                <TableCell align="right">{row.StudentID}</TableCell>
                <TableCell align="right">{row.Message}</TableCell>
                <TableCell align="right">
                  <TextField
                    id="standard-basic"
                    label="Reply"
                    variant="standard"
                  />
                </TableCell>
                <TableCell align="right">{row.Submit}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
