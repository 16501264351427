import React from "react";
import { useNavigate } from "react-router-dom";

const ChatBody = ({ messages, typingStatus, lastMessageRef }) => {
  const navigate = useNavigate();
  console.log(messages);
  const handleLeaveChat = () => {
    let usertype = localStorage.getItem("userName");
    if (usertype.startsWith("ST")) {
      navigate("/student");
      localStorage.removeItem("userName");
      window.location.reload();
    }
    if (usertype.startsWith("BO")) {
      navigate("/OwnerHome");
      localStorage.removeItem("userName");
      window.location.reload();
    }
    if (usertype.startsWith("SO")) {
      navigate("/SchoolAdmin");
      localStorage.removeItem("userName");
      window.location.reload();
    }
    if (usertype.startsWith("SU")) {
      navigate("/Superadminhome");
      localStorage.removeItem("userName");
      window.location.reload();
    }
  };

  return (
    <>
      <header className="chat__mainHeader">
        <p>Chat With</p>
        <button className="leaveChat__btn" onClick={handleLeaveChat}>
          LEAVE CHAT
        </button>
      </header>

      <div className="message__container">
        {messages.map((message) =>
          message.name === localStorage.getItem("userName") ? (
            <div className="message__chats" key={message.id}>
              <p className="sender__name">You</p>
              <div className="message__sender">
                <p>{message.text}</p>
              </div>
            </div>
          ) : (
            <div className="message__chats" key={message.id}>
              <p>{message.name}</p>
              <div className="message__recipient">
                <p>{message.text}</p>
              </div>
            </div>
          )
        )}

        <div className="message__status">
          <p>{typingStatus}</p>
        </div>
        <div ref={lastMessageRef} />
      </div>
    </>
  );
};

export default ChatBody;
