import React from "react";
import {
  Button,
  TextField,
  Grid,
  Paper,
  AppBar,
  Typography,
  Toolbar,
  Link,
} from "@material-ui/core";
import FormControl from "@mui/material/FormControl";
import axios from "axios";

const BRAND_NAME = "Mercado Escolar";

const options = {
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Content-Type": "application/json",
  },
};

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      BoID: "",
      authflag: 1,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  handleChange(event) {
    this.setState({
      BoID: event.state.BoID,
    });
  }
  handleSubmit(event) {
    event.preventDefault();
    console.log(this.state);
    try {
      event.preventDefault();
      event.persist();

      axios
        .post(
          "/sql/SchoolAdmin/DeleteOwner.php",
          {
            authflag: 1,
            BoID: this.state.BoID,
          },
          options
        )
        .then((response) => {
          console.log(response, "response");
          return;
        });
    } catch (error) {
      throw error;
    }
  }
  render() {
    if (localStorage.getItem("userType") == "SO") {
      return (
        <div>
          <AppBar position="static" alignitems="center" color="primary">
            <Toolbar>
              <Grid container justify="center" wrap="wrap">
                <Grid item>
                  <Typography variant="h6">{BRAND_NAME}</Typography>
                </Grid>
              </Grid>
            </Toolbar>
          </AppBar>
          <Grid container spacing={0} justify="center" direction="row">
            <Grid item>
              <Grid
                container
                direction="column"
                justify="center"
                spacing={2}
                className="login-form"
              >
                <Paper
                  variant="elevation"
                  elevation={8}
                  className="login-background"
                >
                  <Grid item>
                    <Typography component="h1" variant="h5">
                      Delete Bussiness Owner
                    </Typography>
                  </Grid>
                  <FormControl sx={{ m: 1, minWidth: 450 }}>
                    <Grid item>
                      <form onSubmit={this.handleSubmit}>
                        <Grid container direction="column" spacing={2}>
                          <Grid item>
                            <TextField
                              label="Bussiness Owner ID"
                              type="BoID"
                              fullWidth
                              name="BoID"
                              variant="outlined"
                              value={this.state.BoID}
                              onChange={(event) =>
                                this.setState({
                                  [event.target.name]: event.target.value,
                                })
                              }
                              required
                              autoFocus
                            />
                          </Grid>
                          <Grid item>
                            <Button
                              //   href="/ManageOwner"
                              variant="contained"
                              color="secondary"
                              type="submit"
                              className="button-block"
                            >
                              Delete?
                            </Button>
                          </Grid>
                          <Grid item>
                            <Button
                              href="/ManageSowner"
                              variant="contained"
                              color="secondary"
                              type="submit"
                              className="button-block"
                            >
                              View Owners
                            </Button>
                          </Grid>
                        </Grid>
                      </form>
                    </Grid>
                  </FormControl>
                </Paper>
              </Grid>
            </Grid>
          </Grid>
        </div>
      );
    } else {
      if (localStorage.getItem("userType") == "SU") {
        window.location.href = "/Superadminhome";
      } else if (localStorage.getItem("userType") == "BO") {
        window.location.href = "/OwnerHome";
      } else if (localStorage.getItem("userType") == "ST") {
        window.location.href = "/student";
      } else {
        localStorage.clear();
        window.location.href = "/login";
      }
    }
  }
}
export default Login;
