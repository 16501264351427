import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { Link } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";

import {
  Button,
  TextField,
  Grid,
  AppBar,
  Typography,
  Toolbar,
} from "@material-ui/core";
import axios from "axios";

const BRAND_NAME = "Mercado Escolar";
const useStyles = (theme) => ({
  table: {
    width: "80%",
    margin: "auto",
  },
});

function createData(OrderID, ProductID, SID, Modify) {
  return {
    OrderID,
    ProductID,
    SID,
    Modify,
  };
}

// function handleDelete(row, e) {
//   console.log(row);
//   console.log(row.ProductID);
//   try {
//     e.preventDefault();
//     e.persist();

//     axios
//       .post("/sql/Student/AddCart.php", {
//         ProductID: row.ProductID,
//         userId: localStorage.getItem("userId"),
//       })
//       .then((response) => {
//         console.log(response, "response");
//         window.location.href = "/Cart";
//         return;
//       });
//   } catch (error) {
//     throw error;
//   }
// }

class ManageStudent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userData: [],
    };
  }

  componentDidMount() {
    if (localStorage.getItem("userType") == "ST") {
      try {
        axios
          .get(
            "/sql/Student/PastOrders.php?userId=" +
              localStorage.getItem("userId")
          )
          .then((response) => {
            let rows = [];
            console.log(response);
            response.data.userlist.userdata.forEach(function (item, index) {
              rows.push(createData(item.OrderID, item.ProductID, item.SID, ""));
            });
            this.setState({ userData: rows });
            console.log("state = ", this.state);
            return;
          });
      } catch (error) {
        throw error;
      }
    } else {
      if (localStorage.getItem("userType") == "BO") {
        window.location.href = "/OwnerHome";
      } else if (localStorage.getItem("userType") == "SO") {
        window.location.href = "/SchoolAdmin";
      } else if (localStorage.getItem("userType") == "SU") {
        window.location.href = "/Superadminhome";
      } else {
        localStorage.clear();
        window.location.href = "/login";
      }
    }
  }

  render() {
    const { classes } = this.props;

    return (
      <div>
        <AppBar position="static" alignitems="center" color="primary">
          <Toolbar>
            <Grid container justify="center" wrap="wrap">
              <Grid item>
                <Typography variant="h6">{BRAND_NAME}</Typography>
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
        <Grid container direction="row" justify="center" spacing={2}>
          <Grid item>
            <Button
              href="/student"
              variant="contained"
              color="Secondary"
              type="submit"
              className="button-block"
            >
              Student Home
            </Button>
          </Grid>
          <Grid item>
            <Button
              href="/SellProducts"
              variant="contained"
              color="Secondary"
              type="submit"
              className="button-block"
            >
              Sell Product
            </Button>
          </Grid>
          <Grid item>
            <Button
              href="/Orders"
              variant="contained"
              color="Secondary"
              type="submit"
              className="button-block"
            >
              Previous Orders
            </Button>
          </Grid>
          <Grid item>
            <Button
              href="/Cart"
              variant="contained"
              color="Secondary"
              type="submit"
              className="button-block"
            >
              View Cart
            </Button>
          </Grid>
        </Grid>

        <TableContainer component={Paper}>
          <Table aria-label="simple table" className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell align="right">OrderID ID</TableCell>
                <TableCell align="right">Product ID</TableCell>
                <TableCell align="right">Student ID</TableCell>
                <TableCell align="right">Action?</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {this.state.userData.map((row) => (
                <TableRow key={row.number}>
                  <TableCell align="right">{row.OrderID}</TableCell>
                  <TableCell align="right">{row.ProductID}</TableCell>
                  <TableCell align="right">{row.SID}</TableCell>
                  <TableCell align="right">
                    <Button
                      href="/ReturnProducts"
                      variant="contained"
                      color="Secondary"
                      type="submit"
                      className="button-block"
                      //   onClick={(e) => handleDelete(row, e)}
                    >
                      Return?
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    );
  }
}
export default withStyles(useStyles)(ManageStudent);
