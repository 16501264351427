import React from "react";
import {
  Button,
  TextField,
  Grid,
  Paper,
  AppBar,
  Typography,
  Toolbar,
  Link,
} from "@material-ui/core";

const BRAND_NAME = "Mercado Escolar";

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      AdvertismentID: "",
      StudentID: "",
      Reason: "",
      authflag: 1,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    if (localStorage.getItem("userType") == "ST") {
      console.log("");
    } else {
      if (localStorage.getItem("userType") == "BO") {
        window.location.href = "/OwnerHome";
      } else if (localStorage.getItem("userType") == "SO") {
        window.location.href = "/SchoolAdmin";
      } else if (localStorage.getItem("userType") == "SU") {
        window.location.href = "/Superadminhome";
      } else {
        localStorage.clear();
        window.location.href = "/login";
      }
    }
  }

  handleChange(event) {
    this.setState({
      AdvertismentID: event.state.AdvertismentID,
      SellerID: event.state.SellerID,
      StudentID: event.state.StudentID,
      Reason: event.state.Reason,
    });
  }
  handleSubmit(event) {
    event.preventDefault();
    if (
      this.state.StudentID == "admin@littech.in" &&
      this.state.FirstName == "secret"
    ) {
      this.props.history.push("/home");
    } else {
      alert("Incorrect Credntials!");
    }
  }
  render() {
    return (
      <div>
        <AppBar position="static" alignitems="center" color="primary">
          <Toolbar>
            <Grid container justify="center" wrap="wrap">
              <Grid item>
                <Typography variant="h6">{BRAND_NAME}</Typography>
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
        <Grid container spacing={0} justify="center" direction="row">
          <Grid item>
            <Grid
              container
              direction="column"
              justify="center"
              spacing={2}
              className="login-form"
            >
              <Paper
                variant="elevation"
                elevation={8}
                className="login-background"
              >
                <Grid item>
                  <Typography component="h1" variant="h5">
                    Delete Advertisment
                  </Typography>
                </Grid>
                <Grid item>
                  <form onSubmit={this.handleSubmit}>
                    <Grid container direction="column" spacing={2}>
                      <Grid item>
                        <TextField
                          label="Advertisment ID"
                          type="AdvertismentID"
                          fullWidth
                          name="AdvertismentID"
                          variant="outlined"
                          value={this.state.AdvertismentID}
                          onChange={(event) =>
                            this.setState({
                              [event.target.name]: event.target.value,
                            })
                          }
                          required
                          autoFocus
                        />
                      </Grid>
                      <Grid item>
                        <TextField
                          label="StudentID"
                          type="StudentID"
                          fullWidth
                          name="StudentID"
                          variant="outlined"
                          value={this.state.StudentID}
                          onChange={(event) =>
                            this.setState({
                              [event.target.name]: event.target.value,
                            })
                          }
                          required
                        />
                      </Grid>
                      <Grid item>
                        <TextField
                          label="Reason"
                          type="Reason"
                          fullWidth
                          name="Reason"
                          variant="outlined"
                          value={this.state.Reason}
                          onChange={(event) =>
                            this.setState({
                              [event.target.name]: event.target.value,
                            })
                          }
                          required
                        />
                      </Grid>
                      <Grid item>
                        <Button
                          variant="contained"
                          color="primary"
                          type="submit"
                          className="button-block"
                        >
                          Return
                        </Button>
                      </Grid>
                    </Grid>
                  </form>
                </Grid>
                <Grid item>
                  <Link href="/student" variant="body2">
                    Home
                  </Link>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  }
}
export default Login;
