import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { Link } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";

import {
  Button,
  TextField,
  Grid,
  AppBar,
  Typography,
  Toolbar,
} from "@material-ui/core";
import axios from "axios";

const BRAND_NAME = "Mercado Escolar";
const useStyles = (theme) => ({
  table: {
    width: "80%",
    margin: "auto",
  },
});

function createData(
  BoId,
  FirstName,
  LastName,
  ContactNumber,
  Email,
  DOB,
  Address,
  Password,
  Age,
  Modify
) {
  return {
    BoId,
    FirstName,
    LastName,
    ContactNumber,
    Email,
    DOB,
    Address,
    Password,
    Age,
    Modify,
  };
}

class ManageOwner extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userData: [],
    };
  }

  componentDidMount() {
    if (localStorage.getItem("userType") == "SO") {
      try {
        axios.get("/sql/SchoolAdmin/ManageOwner.php").then((response) => {
          let rows = [];
          console.log(response);
          response.data.userlist.userdata.forEach(function (item, index) {
            rows.push(
              createData(
                item.BoID,
                item.FirstName,
                item.LastName,
                item.ContactNumber,
                item.Email,
                item.DOB,
                item.Address,
                item.Password,
                item.Age,
                ""
              )
            );
          });
          this.setState({ userData: rows });
          console.log("state = ", this.state);
          return;
        });
      } catch (error) {
        throw error;
      }
    } else {
      if (localStorage.getItem("userType") == "SU") {
        window.location.href = "/Superadminhome";
      } else if (localStorage.getItem("userType") == "BO") {
        window.location.href = "/OwnerHome";
      } else if (localStorage.getItem("userType") == "ST") {
        window.location.href = "/student";
      } else {
        localStorage.clear();
        window.location.href = "/login";
      }
    }
  }

  render() {
    const { classes } = this.props;

    return (
      <div>
        <AppBar position="static" alignitems="center" color="primary">
          <Toolbar>
            <Grid container justify="center" wrap="wrap">
              <Grid item>
                <Typography variant="h6">{BRAND_NAME}</Typography>
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
        <Grid container direction="row" justify="center" spacing={2}>
          <Grid item>
            <Button
              href="/SchoolAdmin"
              variant="contained"
              color="Secondary"
              type="submit"
              className="button-block"
            >
              Home
            </Button>
          </Grid>
          <Grid item>
            <Button
              href="/AddSowner"
              variant="contained"
              color="Secondary"
              type="submit"
              className="button-block"
            >
              Add Bussiness Owner
            </Button>
          </Grid>
          <Grid item>
            <Button
              href="/DeletesOwner"
              variant="contained"
              color="Secondary"
              type="submit"
              className="button-block"
            >
              Delete Bussiness Owner?
            </Button>
          </Grid>
        </Grid>
        <TableContainer component={Paper}>
          <Table aria-label="simple table" className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell align="right">ID</TableCell>
                <TableCell align="right">First Name</TableCell>
                <TableCell align="right">Last Name</TableCell>
                <TableCell align="right">Contact Number</TableCell>
                <TableCell align="right">Email ID</TableCell>
                <TableCell align="right">Date of Birth</TableCell>
                <TableCell align="right">Address</TableCell>
                <TableCell align="right">Password</TableCell>
                <TableCell align="right">Age</TableCell>
                <TableCell align="right">Action?</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {this.state.userData.map((row) => (
                <TableRow key={row.number}>
                  <TableCell align="right">{row.BoId}</TableCell>
                  <TableCell align="right">{row.FirstName}</TableCell>
                  <TableCell align="right">{row.LastName}</TableCell>
                  <TableCell align="right">{row.ContactNumber}</TableCell>
                  <TableCell align="right">{row.Email}</TableCell>
                  <TableCell align="right">{row.DOB}</TableCell>
                  <TableCell align="right">{row.Address}</TableCell>
                  <TableCell align="right">{row.Password}</TableCell>
                  <TableCell align="right">{row.Age}</TableCell>
                  <TableCell align="right">
                    <Button
                      href="/ModifySowner"
                      variant="contained"
                      color="Secondary"
                      type="submit"
                      className="button-block"
                    >
                      Modify?
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    );
  }
}
export default withStyles(useStyles)(ManageOwner);
