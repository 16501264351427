import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { Link } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";

import {
  Button,
  TextField,
  Grid,
  AppBar,
  Typography,
  Toolbar,
} from "@material-ui/core";
import axios from "axios";

const BRAND_NAME = "Mercado Escolar";
const useStyles = (theme) => ({
  table: {
    width: "80%",
    margin: "auto",
  },
});

function createData(ClubID, ClubOwnerID, ClubName, Description, Moderate) {
  return {
    ClubID,
    ClubOwnerID,
    ClubName,
    Description,
    Moderate,
  };
}

function handleDelete(row, e) {
  console.log(row);
  console.log(row.ClubID);
  try {
    e.preventDefault();
    e.persist();

    axios
      .post("/sql/Student/JoinClub.php", {
        ClubID: row.ClubID,
        userId: localStorage.getItem("userId"),
      })
      .then((response) => {
        console.log(response, "response");
        window.location.href = "/JoinedClub";
        return;
      });
  } catch (error) {
    throw error;
  }
}

class ManageStudent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userData: [],
    };
  }

  componentDidMount() {
    if (localStorage.getItem("userType") == "ST") {
      try {
        axios.get("/sql/Student/ManageClub.php").then((response) => {
          let rows = [];
          console.log(response);
          response.data.userlist.userdata.forEach(function (item, index) {
            rows.push(
              createData(
                item.ClubID,
                item.ClubOwnerID,
                item.ClubName,
                item.Description,
                ""
              )
            );
          });
          this.setState({ userData: rows });
          console.log("state = ", this.state);
          return;
        });
      } catch (error) {
        throw error;
      }
    } else {
      if (localStorage.getItem("userType") == "BO") {
        window.location.href = "/OwnerHome";
      } else if (localStorage.getItem("userType") == "SO") {
        window.location.href = "/SchoolAdmin";
      } else if (localStorage.getItem("userType") == "SU") {
        window.location.href = "/Superadminhome";
      } else {
        localStorage.clear();
        window.location.href = "/login";
      }
    }
  }

  render() {
    const { classes } = this.props;

    return (
      <div>
        <AppBar position="static" alignitems="center" color="primary">
          <Toolbar>
            <Grid container justify="center" wrap="wrap">
              <Grid item>
                <Typography variant="h6">{BRAND_NAME}</Typography>
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
        <Grid container direction="row" justify="center" spacing={2}>
          <Grid item>
            <Button
              href="/student"
              variant="contained"
              color="Secondary"
              type="submit"
              className="button-block"
            >
              Student Home
            </Button>
          </Grid>
          <Grid item>
            <Button
              href="/AddClub"
              variant="contained"
              color="Secondary"
              type="submit"
              className="button-block"
            >
              Create New Club
            </Button>
          </Grid>
          <Grid item>
            <Button
              href="/JoinedClub"
              variant="contained"
              color="Secondary"
              type="submit"
              className="button-block"
            >
              Joined Clubs
            </Button>
          </Grid>
          <Grid item>
            <Button
              href="/MyClub"
              variant="contained"
              color="Secondary"
              type="submit"
              className="button-block"
            >
              My Clubs
            </Button>
          </Grid>
        </Grid>

        <TableContainer component={Paper}>
          <Table aria-label="simple table" className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell align="right">Club ID</TableCell>
                <TableCell align="right">Club Owner ID</TableCell>
                <TableCell align="right">Club Name</TableCell>
                <TableCell align="right">Description</TableCell>
                <TableCell align="right">Action?</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {this.state.userData.map((row) => (
                <TableRow key={row.number}>
                  <TableCell align="right">{row.ClubID}</TableCell>
                  <TableCell align="right">{row.ClubOwnerID}</TableCell>
                  <TableCell align="right">{row.ClubName}</TableCell>
                  <TableCell align="right">{row.Description}</TableCell>
                  <TableCell align="right">
                    <Button
                      href="#"
                      variant="contained"
                      color="Secondary"
                      type="submit"
                      className="button-block"
                      onClick={(e) => handleDelete(row, e)}
                    >
                      Join?
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    );
  }
}
export default withStyles(useStyles)(ManageStudent);
