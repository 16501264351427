import React from "react";
import LogoutIcon from "@mui/icons-material/Logout";

class LogoutButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};

    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(event) {
    event.preventDefault();
    localStorage.clear();
    window.location.href = "/login";
  }

  render() {
    return (
      <button
        id="logout-button"
        size="large"
        onClick={this.handleClick}
        startIcon={<LogoutIcon />}
      >
        Log out
      </button>
    );
  }
}

export default LogoutButton;
