import * as React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { AppBar, Toolbar, CssBaseline, makeStyles } from "@material-ui/core";
import { Link } from "react-router-dom";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const useStyles = makeStyles((theme) => ({
  navlinks: {
    marginLeft: theme.spacing(10),
    display: "flex",
  },
  logo: {
    flexGrow: "1",
    cursor: "pointer",
  },
  link: {
    textDecoration: "none",
    color: "white",
    fontSize: "20px",
    marginLeft: theme.spacing(20),
    "&:hover": {
      color: "yellow",
      borderBottom: "1px solid white",
    },
  },
}));

export default function BasicGrid() {
  const classes = useStyles();
  return (
    <div>
      <AppBar position="static">
        <CssBaseline />
        <Toolbar>
          <Typography variant="h4" className={classes.logo}>
            Mercado Escolar
          </Typography>
          <div className={classes.navlinks}>
            <Link to="/" className={classes.link}>
              Home
            </Link>
            <Link to="/services" className={classes.link}>
              Services
            </Link>
            <Link to="/about" className={classes.link}>
              About Us
            </Link>
            <Link to="/contact" className={classes.link}>
              Contact Us
            </Link>
            <Link to="/login" className={classes.link}>
              Account
            </Link>
          </div>
        </Toolbar>
      </AppBar>

      <Box sx={{ flexGrow: 1 }} m={2} pt={3}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Item>
              <Typography variant="body2" gutterBottom>
                <h2>We at Mercado Escolar</h2>
                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos
                blanditiis tenetur unde suscipit, quam beatae rerum inventore
                consectetur, neque doloribus, cupiditate numquam dignissimos
                laborum fugiat deleniti? Eum quasi quidem quibusdam. Lorem ipsum
                dolor sit amet, consectetur adipisicing elit. Quos blanditiis
                tenetur unde suscipit, quam beatae rerum inventore consectetur,
                neque doloribus, cupiditate numquam dignissimos laborum fugiat
                deleniti? Eum quasi quidem quibusdam. Lorem ipsum dolor sit
                amet, consectetur adipisicing elit. Quos blanditiis tenetur unde
                suscipit, quam beatae rerum inventore consectetur, neque
                doloribus, cupiditate numquam dignissimos laborum fugiat
                deleniti? Eum quasi quidem quibusdam.
              </Typography>
            </Item>
          </Grid>
          {/* <Grid item xs={4}>
            <Item>
              <Typography variant="body2" gutterBottom>
                <h2>Sell</h2>
                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos
                blanditiis tenetur unde suscipit, quam beatae rerum inventore
                consectetur, neque doloribus, cupiditate numquam dignissimos
                laborum fugiat deleniti? Eum quasi quidem quibusdam.
              </Typography>
            </Item>
          </Grid>
          <Grid item xs={4}>
            <Item>
              <Typography variant="body2" gutterBottom>
                <h2>Explore</h2>
                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos
                blanditiis tenetur unde suscipit, quam beatae rerum inventore
                consectetur, neque doloribus, cupiditate numquam dignissimos
                laborum fugiat deleniti? Eum quasi quidem quibusdam.
              </Typography>
            </Item>
          </Grid> */}
        </Grid>
      </Box>
      <a href="http://nmk7272.uta.cloud/">Blog</a>
    </div>
  );
}
