import logo from "./logo.svg";
import "./App.css";
import ChatHome from "./Chat/components/Home";
import ChatPage from "./Chat/components/ChatPage";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import ManageStudent from "./SuperAdmin/Manages/ManageStudent";
import ManageSchoolAdmin from "./SuperAdmin/Manages/ManageSchoolAdmin";
import ManageOwner from "./SuperAdmin/Manages/ManageOwner";
import ModifySchoolAdmin from "./SuperAdmin/Manages/ModifySchoolAdmin";
import ModifyStudent from "./SuperAdmin/Manages/ModifyStudent";
import DeleteStudent from "./SuperAdmin/Manages/DeleteStudent";
import ModifyOwner from "./SuperAdmin/Manages/ModifyOwner";
import ModifyOwner1 from "./SchoolAdmin/Manages/ModifyOwner";
import Login from "./login/login";
import Register from "./login/Register";
import Forget from "./login/Forgot";
import AddStudent from "./SuperAdmin/Manages/AddStudent";
import DeleteSA from "./SuperAdmin/Manages/DeleteSA";
import DeleteOwner from "./SuperAdmin/Manages/DeleteOwner";
import AddOwner from "./SuperAdmin/Manages/AddOwner";
import AddSchoolAdmin from "./SuperAdmin/Manages/AddSchoolAdmin";
import ManageStudent1 from "./SchoolAdmin/Manages/ManageStudent";
import AddStudent1 from "./SchoolAdmin/Manages/AddStudent";
import DeleteStudent1 from "./SchoolAdmin/Manages/DeleteStudent";
import DeleteOwner1 from "./SchoolAdmin/Manages/DeleteOwner";
import ModifyStudent1 from "./SchoolAdmin/Manages/ModifyStudent";
import ManageOwner1 from "./SchoolAdmin/Manages/ManageOwner";
import AddOwner1 from "./SchoolAdmin/Manages/AddOwner";
import Report1 from "./SchoolAdmin/Manages/Report";
import Report from "./SuperAdmin/Manages/Report";
import ManagePost from "./SchoolAdmin/Manages/ManagePost";
import ManageClubs from "./SchoolAdmin/Manages/ManageClubs";
import Product from "./Student/Manages/Product";
import Club from "./Student/Manages/Club";
import PastOrders from "./Student/Manages/PastOrders";
import Profile from "./Student/Manages/Profile";
import JoinedClub from "./Student/Manages/JoinedClub";
import Ads from "./Student/Manages/Ad";
import AddClub from "./Student/Manages/AddClub";
import MyClub from "./Student/Manages/Myclubs";
import DeleteClub from "./Student/Manages/DeleteClub";
import LeaveClub from "./Student/Manages/LeaveClub";
import SellProduct from "./Student/Manages/SellProduct";
import Cart from "./Student/Manages/Cart";
import Payment from "./Student/Manages/Payment";
import Orders from "./Student/Manages/Orders";
import ReturnProduct from "./Student/Manages/ReturnProduct";
import AddAd from "./Student/Manages/AddAd";
import MyAds from "./Student/Manages/MyAds";
import DeleteAd from "./Student/Manages/DeleteAd";
import ManageProducts from "./Owner/Manages/ManageProducts";
import ModifyProducts from "./Owner/Manages/ModifyProduct";
import DeleteProducts from "./Owner/Manages/DeleteProduct";
import AddProducts from "./Owner/Manages/AddProduct";
import ManageAds from "./Owner/Manages/ManageAds";
import AddAds from "./Owner/Manages/AddAd";
import DeleteAds from "./Owner/Manages/DeleteAd";
import Owner from "./Owner/Owner";
import StudentHome from "./Student/StudentHome";
import SuperAdminHome from "./SuperAdmin/SuperAdminHome.js";
import SchoolAdminHome from "./SchoolAdmin/SchoolAdminHome";
import ContactUs from "./ContactUs";
import About from "./About";
import Services from "./Services";
import Chat from "./Owner/Manages/Chat";
import Chats from "./SuperAdmin/Manages/Chat";
// import socketIO from "socket.io-client";
// import HomePage from "./HomePage.tsx";
import Home from "./Home";
// const socket = socketIO.connect("http://localhost:4000");
function App() {
  return (
    <div className="App">
      {/* <ContactUs /> */}
      <BrowserRouter>
        <Routes>
          <Route path="Chathome" element={<ChatHome />} />
          <Route path="Chatpage" element={<ChatPage />} />
          <Route path="/" element={<Home />}></Route>
          <Route path="/Superadminhome" element={<SuperAdminHome />}></Route>
          <Route path="/student" element={<StudentHome />} />
          <Route path="/SchoolAdmin" element={<SchoolAdminHome />} />
          <Route path="OwnerHome" element={<Owner />} />
          <Route path="contact" element={<ContactUs />} />
          <Route path="about" element={<About />} />
          <Route path="Services" element={<Services />} />
          <Route path="login" element={<Login />} />
          <Route path="Register" element={<Register />} />
          <Route path="forget" element={<Forget />} />
          <Route path="ManageStudent" element={<ManageStudent />} />
          <Route path="ManageSchoolAdmin" element={<ManageSchoolAdmin />} />
          <Route path="Modifyschooladmin" element={<ModifySchoolAdmin />} />
          <Route path="ModifyStudent" element={<ModifyStudent />} />
          <Route path="DeleteStudent" element={<DeleteStudent />} />
          <Route path="DeleteSA" element={<DeleteSA />} />
          <Route path="DeleteOwner" element={<DeleteOwner />} />
          <Route path="ModifyOwner" element={<ModifyOwner />} />
          <Route path="ManageOwner" element={<ManageOwner />} />
          <Route path="Addschooladmin" element={<AddSchoolAdmin />} />
          <Route path="AddStudent" element={<AddStudent />} />
          <Route path="DeletesStudent" element={<DeleteStudent1 />} />
          <Route path="DeletesOwner" element={<DeleteOwner1 />} />
          <Route path="AddOwner" element={<AddOwner />} />
          <Route path="Report1" element={<Report1 />} />
          <Route path="Report" element={<Report />} />
          <Route path="Manageproducts" element={<ManageProducts />} />
          <Route path="Products" element={<Product />} />
          <Route path="Club" element={<Club />} />
          <Route path="PastOrders" element={<PastOrders />} />
          <Route path="Profile" element={<Profile />} />
          <Route path="MyClub" element={<MyClub />} />
          <Route path="JoinedClub" element={<JoinedClub />} />
          <Route path="AddClub" element={<AddClub />} />
          <Route path="DeleteClub" element={<DeleteClub />} />
          <Route path="LeaveClub" element={<LeaveClub />} />
          <Route path="SellProducts" element={<SellProduct />} />
          <Route path="Cart" element={<Cart />} />
          <Route path="Payment" element={<Payment />} />
          <Route path="Orders" element={<Orders />} />
          <Route path="ReturnProducts" element={<ReturnProduct />} />
          <Route path="Ads" element={<Ads />} />
          <Route path="AddAd" element={<AddAd />} />
          <Route path="MyAds" element={<MyAds />} />
          <Route path="AddAds" element={<AddAds />} />
          <Route path="DeleteAds" element={<DeleteAd />} />
          <Route path="ManageSstudent" element={<ManageStudent1 />} />
          <Route path="ManageSowner" element={<ManageOwner1 />} />
          <Route path="AddSstudent" element={<AddStudent1 />} />
          <Route path="AddSowner" element={<AddOwner1 />} />
          <Route path="ModifySstudent" element={<ModifyStudent1 />} />
          <Route path="ModifySowner" element={<ModifyOwner1 />} />
          <Route path="ManageSclub" element={<ManageClubs />} />
          <Route path="ManageSpost" element={<ManagePost />} />
          <Route path="ModifyProduct" element={<ModifyProducts />} />
          <Route path="DeleteProduct" element={<DeleteProducts />} />
          <Route path="ManageSproduct" element={<ManageProducts />} />
          <Route path="AddSproduct" element={<AddProducts />} />
          <Route path="ManageAds" element={<ManageAds />} />
          <Route path="AddAds" element={<AddAds />} />
          <Route path="DeleteAds" element={<DeleteAds />} />
          <Route path="Chat" element={<Chat />} />
          <Route path="Chats" element={<Chats />} />
          {/* <Route path="contact" element={<ContactUs />} /> */}
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
