import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

const Home = ({ socket }) => {
  const navigate = useNavigate();
  const [setUserName] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    let user = localStorage.getItem("userId");
    localStorage.setItem("userName", user);
    console.log(user);
    socket.emit("newUser", { user, socketID: socket.id });
    navigate("/Chatpage");
  };
  return (
    <form className="home__container" onSubmit={handleSubmit}>
      <h2 className="home__header">Sign in to Open Chat</h2>
      <label for="cars">With Whom Do you Want to Chat:</label>

      <select name="Chat" id="chat">
        <option value="ST">Student</option>
        <option value="BO">Bussiness Owner</option>
        <option value="SO">SchoolAdmin</option>
        <option value="SU">SuperAdmin</option>
      </select>
      <label htmlFor="username">Username</label>
      <input
        type="text"
        minLength={2}
        name="username"
        id="username"
        className="username__input"
        value={localStorage.getItem("userId")}
        disabled
        onChange={(e) => setUserName(e.target.value)}
      />
      <button className="home__cta">Start</button>
    </form>
  );
};

export default Home;
