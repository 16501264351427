import React from "react";
import {
  Button,
  TextField,
  Grid,
  Paper,
  AppBar,
  Typography,
  Toolbar,
  Link,
} from "@material-ui/core";
import axios from "axios";

const BRAND_NAME = "Mercado Escolar";

const options = {
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Content-Type": "application/json",
  },
};

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ProductID: "",
      SellerID: localStorage.getItem("userId"),
      ProductName: "",
      Description: "",
      Price: "",
      authflag: 1,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    if (localStorage.getItem("userType") == "ST") {
      console.log("");
    } else {
      if (localStorage.getItem("userType") == "BO") {
        window.location.href = "/OwnerHome";
      } else if (localStorage.getItem("userType") == "SO") {
        window.location.href = "/SchoolAdmin";
      } else if (localStorage.getItem("userType") == "SU") {
        window.location.href = "/Superadminhome";
      } else {
        localStorage.clear();
        window.location.href = "/login";
      }
    }
  }

  handleChange(event) {
    this.setState({
      ProductID: event.state.ProductID,
      SellerID: event.state.SellerID,
      ProductName: event.state.ProductName,
      Description: event.state.Description,
      Price: event.state.Price,
    });
  }
  handleSubmit(event) {
    event.preventDefault();
    console.log(this.state);
    try {
      event.preventDefault();
      event.persist();

      axios
        .post(
          "/sql/Student/SellProduct.php",
          {
            authflag: 1,
            ProductID: this.state.ProductID,
            SellerID: this.state.SellerID,
            ProductName: this.state.ProductName,
            Description: this.state.Description,
            Price: this.state.Price,
          },
          options
        )
        .then((response) => {
          console.log(response, "response");
          return;
        });
    } catch (error) {
      throw error;
    }
  }
  render() {
    return (
      <div>
        <AppBar position="static" alignitems="center" color="primary">
          <Toolbar>
            <Grid container justify="center" wrap="wrap">
              <Grid item>
                <Typography variant="h6">{BRAND_NAME}</Typography>
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
        <Grid container spacing={0} justify="center" direction="row">
          <Grid item>
            <Grid
              container
              direction="column"
              justify="center"
              spacing={2}
              className="login-form"
            >
              <Paper
                variant="elevation"
                elevation={8}
                className="login-background"
              >
                <Grid item>
                  <Typography component="h1" variant="h5">
                    Sell New Product
                  </Typography>
                </Grid>
                <Grid item>
                  <form onSubmit={this.handleSubmit}>
                    <Grid container direction="column" spacing={2}>
                      <Grid item>
                        <TextField
                          label="Product ID"
                          type="ProductID"
                          fullWidth
                          name="ProductID"
                          variant="outlined"
                          value={this.state.ProductID}
                          onChange={(event) =>
                            this.setState({
                              [event.target.name]: event.target.value,
                            })
                          }
                          required
                          autoFocus
                        />
                      </Grid>
                      <Grid item>
                        <TextField
                          label="Seller ID"
                          type="SellerID"
                          fullWidth
                          name="SellerID"
                          variant="outlined"
                          value={this.state.SellerID}
                          disabled
                          onChange={(event) =>
                            this.setState({
                              [event.target.name]: event.target.value,
                            })
                          }
                          required
                          autoFocus
                        />
                      </Grid>
                      <Grid item>
                        <TextField
                          label="Product Name"
                          type="ProductName"
                          fullWidth
                          name="ProductName"
                          variant="outlined"
                          value={this.state.ProductName}
                          onChange={(event) =>
                            this.setState({
                              [event.target.name]: event.target.value,
                            })
                          }
                          required
                        />
                      </Grid>
                      <Grid item>
                        <TextField
                          label="Description"
                          type="Description"
                          fullWidth
                          name="Description"
                          variant="outlined"
                          value={this.state.Description}
                          onChange={(event) =>
                            this.setState({
                              [event.target.name]: event.target.value,
                            })
                          }
                          required
                        />
                      </Grid>
                      <Grid item>
                        <TextField
                          label="Price"
                          type="Price"
                          fullWidth
                          name="Price"
                          variant="outlined"
                          value={this.state.Price}
                          onChange={(event) =>
                            this.setState({
                              [event.target.name]: event.target.value,
                            })
                          }
                          required
                        />
                      </Grid>
                      <Grid item>
                        <Button
                          variant="contained"
                          color="primary"
                          type="submit"
                          className="button-block"
                        >
                          Submit
                        </Button>
                      </Grid>
                    </Grid>
                  </form>
                </Grid>
                <Grid container direction="column" spacing={2}>
                  <Grid item>
                    <Button
                      href="/Products"
                      variant="contained"
                      color="Secondary"
                      type="submit"
                      className="button-block"
                    >
                      View Products
                    </Button>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  }
}
export default Login;
