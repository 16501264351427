import React from "react";
import {
  Button,
  TextField,
  Grid,
  Paper,
  AppBar,
  Typography,
  Toolbar,
  Link,
} from "@material-ui/core";
import axios from "axios";
import FormControl from "@mui/material/FormControl";
const BRAND_NAME = "Mercado Escolar";

const options = {
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Content-Type": "application/json",
  },
};

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      OwnerID: "",
      FirstName: "",
      LastName: "",
      EmailID: "",
      DOB: "",
      Address: "",
      Password: "",
      Age: "",
      authflag: 1,
      ContactNumber: "",
      errors: {},
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  handleValidation() {
    let isFormValid = true;
    let errors = {};
    if (
      this.state.BoID === "" ||
      !(this.state.BoID.length > 2 && this.state.BoID.substring(0, 2) === "BO")
    ) {
      isFormValid = false;
      errors["BoID"] = " ID must start with BO and should be greater than 2";
    }

    if (typeof this.state.FirstName !== "undefined") {
      if (!this.state.FirstName.match(/^[a-zA-Z]+$/)) {
        isFormValid = false;
        errors["name"] = "Name can only have letters";
      }
    }

    if (typeof this.state.LastName !== "undefined") {
      if (!this.state.LastName.match(/^[a-zA-Z]+$/)) {
        isFormValid = false;
        errors["name"] = "Name can only have letters";
      }
    }

    if (typeof this.state.DOB !== "undefined") {
      if (
        !this.state.DOB.match(
          /^(?:0[1-9]|[12]\d|3[01])([\/.-])(?:0[1-9]|1[012])\1(?:19|20)\d\d$/
        )
      ) {
        isFormValid = false;
        errors["DOB"] = "Date should be in DD/MM/YYYY";
      }
    }

    if (!this.state.EmailID) {
      isFormValid = false;
      errors["EmailID"] = "EmailID Cannot be empty";
    }

    if (typeof this.state.EmailID !== "undefined") {
      let lastAtPos = this.state.EmailID.lastIndexOf("@");
      let lastDotPos = this.state.EmailID.lastIndexOf(".");

      if (
        !(
          lastAtPos < lastDotPos &&
          lastAtPos > 0 &&
          this.state.EmailID.indexOf("@@") == -1 &&
          lastDotPos > 2 &&
          this.state.EmailID.length - lastDotPos > 2
        )
      ) {
        isFormValid = false;
        errors["EmailID"] = "Email is not valid";
      }
    }

    if (
      this.state.Age === "" ||
      Number(this.state.Age) < 18 ||
      Number(this.state.Age) > 60
    ) {
      isFormValid = false;
      errors["Age"] = "Age Cannot be less than 18 and greater than 60";
    }

    if (this.state.Address === "") {
      isFormValid = false;
      errors["Address"] = "Address Cannot be empty";
    }
    this.setState({ errors: errors });
    return isFormValid;
  }
  handleChange(event) {
    this.setState({
      BoID: event.state.BoID,
      FirstName: event.state.FirstName,
      LastName: event.state.LastName,
      EmailID: event.state.EmailID,
      DOB: event.state.DOB,
      Address: event.state.Address,
      Password: event.state.Password,
      Age: event.state.Age,
      ContactNumber: event.state.ContactNumber,
    });
  }
  handleSubmit(event) {
    event.preventDefault();
    console.log(this.state);
    try {
      event.preventDefault();
      event.persist();

      axios
        .post(
          "/sql/SchoolAdmin/AddOwner.php",
          {
            authflag: 1,
            BoID: this.state.BoID,
            FirstName: this.state.FirstName,
            LastName: this.state.LastName,
            Email: this.state.EmailID,
            DOB: this.state.DOB,
            Address: this.state.Address,
            Password: this.state.Password,
            Age: this.state.Age,
            ContactNumber: this.state.ContactNumber,
          },
          options
        )
        .then((response) => {
          console.log(response);
          return;
        });
    } catch (error) {
      throw error;
    }
  }

  render() {
    if (localStorage.getItem("userType") == "SO") {
      return (
        <div>
          <AppBar position="static" alignitems="center" color="primary">
            <Toolbar>
              <Grid container justify="center" wrap="wrap">
                <Grid item>
                  <Typography variant="h6">{BRAND_NAME}</Typography>
                </Grid>
              </Grid>
            </Toolbar>
          </AppBar>
          <Grid container spacing={0} justify="center" direction="row">
            <Grid item>
              <Grid
                container
                direction="column"
                justify="center"
                spacing={2}
                className="login-form"
              >
                <Paper
                  variant="elevation"
                  elevation={8}
                  className="login-background"
                >
                  <Grid item>
                    <Typography component="h1" variant="h5">
                      Add Bussiness Owner
                    </Typography>
                  </Grid>
                  <FormControl sx={{ m: 1, minWidth: 450 }}>
                    <Grid item>
                      <form onSubmit={this.handleSubmit}>
                        <Grid container direction="column" spacing={2}>
                          <Grid item>
                            <TextField
                              label="Owner ID"
                              type="BoID"
                              fullWidth
                              name="BoID"
                              variant="outlined"
                              value={this.state.BoID}
                              onChange={(event) =>
                                this.setState({
                                  [event.target.name]: event.target.value,
                                })
                              }
                              required
                              autoFocus
                            />
                            <span style={{ color: "red" }}>
                              {this.state.errors["BoID"]}
                            </span>
                          </Grid>
                          <Grid item>
                            <TextField
                              label="First Name"
                              type="FirstName"
                              fullWidth
                              name="FirstName"
                              variant="outlined"
                              value={this.state.FirstName}
                              onChange={(event) =>
                                this.setState({
                                  [event.target.name]: event.target.value,
                                })
                              }
                              required
                            />
                            <span style={{ color: "red" }}>
                              {this.state.errors["FirstName"]}
                            </span>
                          </Grid>
                          <Grid item>
                            <TextField
                              label="Last Name"
                              type="LastName"
                              fullWidth
                              name="LastName"
                              variant="outlined"
                              value={this.state.LastName}
                              onChange={(event) =>
                                this.setState({
                                  [event.target.name]: event.target.value,
                                })
                              }
                              required
                            />
                            <span style={{ color: "red" }}>
                              {this.state.errors["Lastname"]}
                            </span>
                          </Grid>
                          <Grid item>
                            <TextField
                              label="Email ID"
                              type="EmailID"
                              fullWidth
                              name="EmailID"
                              variant="outlined"
                              value={this.state.EmailID}
                              onChange={(event) =>
                                this.setState({
                                  [event.target.name]: event.target.value,
                                })
                              }
                              required
                            />
                            <span style={{ color: "red" }}>
                              {this.state.errors["EmailID"]}
                            </span>
                          </Grid>
                          <Grid item>
                            <TextField
                              label="Date of Birth"
                              type="DOB"
                              placeholder="mm/dd/yyyy"
                              fullWidth
                              name="DOB"
                              variant="outlined"
                              value={this.state.DOB}
                              onChange={(event) =>
                                this.setState({
                                  [event.target.name]: event.target.value,
                                })
                              }
                              required
                            />
                            <span style={{ color: "red" }}>
                              {this.state.errors["DOB"]}
                            </span>
                          </Grid>
                          <Grid item>
                            <TextField
                              label="Address"
                              type="Address"
                              fullWidth
                              name="Address"
                              variant="outlined"
                              value={this.state.Address}
                              onChange={(event) =>
                                this.setState({
                                  [event.target.name]: event.target.value,
                                })
                              }
                              required
                            />
                            <span style={{ color: "red" }}>
                              {this.state.errors["Address"]}
                            </span>
                          </Grid>
                          <Grid item>
                            <TextField
                              label="Password"
                              type="Password"
                              fullWidth
                              name="Password"
                              variant="outlined"
                              value={this.state.Password}
                              onChange={(event) =>
                                this.setState({
                                  [event.target.name]: event.target.value,
                                })
                              }
                              required
                            />
                          </Grid>
                          <Grid item>
                            <TextField
                              label="Age"
                              type="Age"
                              fullWidth
                              name="Age"
                              variant="outlined"
                              value={this.state.Age}
                              onChange={(event) =>
                                this.setState({
                                  [event.target.name]: event.target.value,
                                })
                              }
                              required
                            />
                            <span style={{ color: "red" }}>
                              {this.state.errors["Age"]}
                            </span>
                          </Grid>
                          <Grid item>
                            <TextField
                              label="ContactNumber"
                              type="ContactNumber"
                              fullWidth
                              name="ContactNumber"
                              variant="outlined"
                              value={this.state.ContactNumber}
                              onChange={(event) =>
                                this.setState({
                                  [event.target.name]: event.target.value,
                                })
                              }
                              required
                            />
                          </Grid>
                          <Grid item>
                            <Button
                              variant="contained"
                              color="secondary"
                              type="submit"
                              className="button-block"
                            >
                              Add?
                            </Button>
                          </Grid>
                          <Grid item>
                            <Button
                              href="/ManageSowner"
                              variant="contained"
                              color="secondary"
                              type="submit"
                              className="button-block"
                            >
                              View Owner
                            </Button>
                          </Grid>
                        </Grid>
                      </form>
                    </Grid>
                  </FormControl>
                </Paper>
              </Grid>
            </Grid>
          </Grid>
        </div>
      );
    } else {
      if (localStorage.getItem("userType") == "SU") {
        window.location.href = "/Superadminhome";
      } else if (localStorage.getItem("userType") == "BO") {
        window.location.href = "/OwnerHome";
      } else if (localStorage.getItem("userType") == "ST") {
        window.location.href = "/student";
      } else {
        localStorage.clear();
        window.location.href = "/login";
      }
    }
  }
}
export default Login;
