import React from "react";
import {
  AppBar,
  Toolbar,
  CssBaseline,
  Typography,
  makeStyles,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import Box from "@mui/material/Box";
import LogoutButton from "../login/Logout";
import { withRouter } from "../login/withRouter";

const useStyles = makeStyles((theme) => ({
  navlinks: {
    marginLeft: theme.spacing(10),
    display: "flex",
  },
  logo: {
    flexGrow: "1",
    cursor: "pointer",
  },
  link: {
    textDecoration: "none",
    color: "white",
    fontSize: "20px",
    marginLeft: theme.spacing(20),
    "&:hover": {
      color: "yellow",
      borderBottom: "1px solid white",
    },
  },
}));

function Navbar() {
  const classes = useStyles();
  console.log("Local data", localStorage.getItem("userInfo"));
  if (localStorage.getItem("userType") == "SO") {
    return (
      <div>
        <AppBar position="static">
          <CssBaseline />
          <Toolbar>
            <Typography variant="h4" className={classes.logo}>
              Mercado Escolar
            </Typography>
            <div className={classes.navlinks}>
              <Link to="/ManageSstudent" className={classes.link}>
                Manage Students
              </Link>
              <Link to="/ManageSowner" className={classes.link}>
                Manage Owner
              </Link>
              <Link to="/ManageSclub" className={classes.link}>
                Manage Club
              </Link>
              <Link to="/ManageSpost" className={classes.link}>
                Manage Post
              </Link>
              <Link to="/Report1" className={classes.link}>
                Reports
              </Link>
              <Link to="/Chathome" className={classes.link}>
                Chat
              </Link>
              <Link to="#" className={classes.link}>
                <LogoutButton />
              </Link>
            </div>
          </Toolbar>
        </AppBar>
        <Box
          component="img"
          sx={{
            height: 700,
            width: "100%",
          }}
          alt="The house from the offer."
          src="https://assets-global.website-files.com/5ad4b5ac3265e94b4f4625ea/6179a579f91da881be2c758b_morning-brew-ShVipPImS6k-unsplash_5c8728bacb20daf02414ecbdf89e0418_2000.png"
        />
      </div>
    );
  } else {
    if (localStorage.getItem("userType") == "BO") {
      window.location.href = "/OwnerHome";
    } else if (localStorage.getItem("userType") == "ST") {
      window.location.href = "/student";
    } else if (localStorage.getItem("userType") == "SU") {
      window.location.href = "/Superadminhome";
    } else {
      localStorage.clear();
      window.location.href = "/login";
    }
  }
}
export default withRouter(Navbar);
