import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { Link } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";

import {
  Button,
  TextField,
  Grid,
  AppBar,
  Typography,
  Toolbar,
} from "@material-ui/core";
import axios from "axios";

const BRAND_NAME = "Mercado Escolar";
const useStyles = (theme) => ({
  table: {
    width: "80%",
    margin: "auto",
  },
});

function createData(AdID, CreaterID, Description, ContactNumber, Moderate) {
  return {
    AdID,
    CreaterID,
    Description,
    ContactNumber,
    Moderate,
  };
}
function handleDelete(row, e) {
  console.log(row);
  console.log(row.AdID);
  try {
    e.preventDefault();
    e.persist();

    axios
      .post("/sql/BusinessOwner/DeleteAd.php", {
        AdID: row.AdID,
        userId: localStorage.getItem("userId"),
      })
      .then((response) => {
        console.log(response, "response");
        window.location.href = "/ManageAds";
        return;
      });
  } catch (error) {
    throw error;
  }
}

class ManageStudent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userData: [],
    };
  }

  componentDidMount() {
    if (localStorage.getItem("userType") == "BO") {
      console.log(localStorage.getItem("userId"));
      try {
        axios
          .get(
            "/sql/BusinessOwner/Ads.php?userId=" +
              localStorage.getItem("userId")
          )
          .then((response) => {
            let rows = [];
            console.log(response);
            response.data.userlist.userdata.forEach(function (item, index) {
              rows.push(
                createData(
                  item.AdID,
                  item.CreaterID,
                  item.Description,
                  item.ContactNumber,
                  ""
                )
              );
            });
            this.setState({ userData: rows });
            console.log("state = ", this.state);
            return;
          });
      } catch (error) {
        throw error;
      }
    } else {
      if (localStorage.getItem("userType") == "ST") {
        window.location.href = "/student";
      } else if (localStorage.getItem("userType") == "SO") {
        window.location.href = "/SchoolAdmin";
      } else if (localStorage.getItem("userType") == "SU") {
        window.location.href = "/Superadminhome";
      } else {
        localStorage.clear();
        window.location.href = "/login";
      }
    }
  }

  render() {
    const { classes } = this.props;
    if (localStorage.getItem("userType") == "BO") {
      return (
        <div>
          <AppBar position="static" alignitems="center" color="primary">
            <Toolbar>
              <Grid container justify="center" wrap="wrap">
                <Grid item>
                  <Typography variant="h6">{BRAND_NAME}</Typography>
                </Grid>
              </Grid>
            </Toolbar>
          </AppBar>
          <Grid container direction="row" justify="center" spacing={2}>
            <Grid item>
              <Button
                href="/OwnerHome"
                variant="contained"
                color="secondary"
                type="submit"
                className="button-block"
              >
                Bussiness Owner Home
              </Button>
            </Grid>
            <Grid item>
              <Button
                href="/AddAds"
                variant="contained"
                color="secondary"
                type="submit"
                className="button-block"
              >
                Add New Advertisement
              </Button>
            </Grid>
          </Grid>

          <TableContainer component={Paper}>
            <Table aria-label="simple table" className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell align="right">Advertisment ID</TableCell>
                  <TableCell align="right">Creater ID</TableCell>
                  <TableCell align="right">Description</TableCell>
                  <TableCell align="right">Contact Number</TableCell>
                  <TableCell align="right">Action?</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {this.state.userData.map((row) => (
                  <TableRow key={row.number}>
                    <TableCell align="right">{row.AdID}</TableCell>
                    <TableCell align="right">{row.CreaterID}</TableCell>
                    <TableCell align="right">{row.Description}</TableCell>
                    <TableCell align="right">{row.ContactNumber}</TableCell>
                    <TableCell align="right">
                      <Button
                        href="#"
                        variant="contained"
                        color="Secondary"
                        type="submit"
                        className="button-block"
                        onClick={(e) => handleDelete(row, e)}
                      >
                        Delete?
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      );
    } else {
      if (localStorage.getItem("userType") == "SU") {
        window.location.href = "/Superadminhome";
      } else if (localStorage.getItem("userType") == "SO") {
        window.location.href = "/SchoolAdmin";
      } else if (localStorage.getItem("userType") == "ST") {
        window.location.href = "/student";
      } else {
        localStorage.clear();
        window.location.href = "/login";
      }
    }
  }
}
export default withStyles(useStyles)(ManageStudent);
