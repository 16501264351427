import React from "react";
import {
  Button,
  TextField,
  Grid,
  Paper,
  AppBar,
  Typography,
  Toolbar,
  Link,
} from "@material-ui/core";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import axios from "axios";
import { withRouter } from "./withRouter";

const BRAND_NAME = "Mercado Escolar";

const options = {
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Content-Type": "application/json",
  },
};

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      Username: "",
      FirstName: "",
      LastName: "",
      EmailID: "",
      DOB: "",
      Address: "",
      Password: "",
      Age: "",
      ContactNumber: "",
      authflag: 1,
      errors: {},
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleValidation() {
    let isFormValid = true;
    let errors = {};
    if (
      this.state.Username === "" ||
      !(
        this.state.Username.length > 2 &&
        (this.state.Username.substring(0, 2) === "BO" ||
          this.state.Username.substring(0, 2) === "ST")
      )
    ) {
      isFormValid = false;
      errors["Username"] =
        "Invalid Username, Please try again and attach prefix and also the length should be greater than 3";
    }

    if (typeof this.state.FirstName !== "undefined") {
      if (!this.state.FirstName.match(/^[a-zA-Z]+$/)) {
        isFormValid = false;
        errors["FirstName"] = "Name can only have letters";
      }
    }

    if (typeof this.state.LastName !== "undefined") {
      if (!this.state.LastName.match(/^[a-zA-Z]+$/)) {
        isFormValid = false;
        errors["LastName"] = "Name can only have letters";
      }
    }

    if (typeof this.state.DOB !== "undefined") {
      if (
        !this.state.DOB.match(
          /^(?:0[1-9]|[12]\d|3[01])([\/.-])(?:0[1-9]|1[012])\1(?:19|20)\d\d$/
        )
      ) {
        isFormValid = false;
        errors["DOB"] = "Date should be in DD/MM/YYYY";
      }
    }

    if (!this.state.EmailID) {
      isFormValid = false;
      errors["EmailID"] = "EmailID Cannot be empty";
    }

    if (typeof this.state.EmailID !== "undefined") {
      let lastAtPos = this.state.EmailID.lastIndexOf("@");
      let lastDotPos = this.state.EmailID.lastIndexOf(".");

      if (
        !(
          lastAtPos < lastDotPos &&
          lastAtPos > 0 &&
          this.state.EmailID.indexOf("@@") == -1 &&
          lastDotPos > 2 &&
          this.state.EmailID.length - lastDotPos > 2
        )
      ) {
        isFormValid = false;
        errors["EmailID"] = "Email is not valid";
      }
    }

    if (
      this.state.Age === "" ||
      Number(this.state.Age) < 18 ||
      Number(this.state.Age) > 60 ||
      isNaN(Number(this.state.Age))
    ) {
      isFormValid = false;
      errors["Age"] = "Age Cannot be less than 18 and greater than 60";
    }

    if (this.state.Address === "") {
      isFormValid = false;
      errors["Address"] = "Address Cannot be empty";
    }

    if (typeof this.state.ContactNumber !== "undefined") {
      if (!this.state.ContactNumber.match(/^[0-9]{10}$/)) {
        isFormValid = false;
        errors["ContactNumber"] = "Please enter valid contact number";
      }
    }

    if (this.state.Password !== "" && this.state.Password.length < 8) {
      isFormValid = false;
      errors["Password"] = "Password should be greater than 8 characters";
    }

    this.setState({ errors: errors });
    return isFormValid;
  }

  handleChange(event) {
    this.setState({
      Username: event.state.Username,
      FirstName: event.state.FirstName,
      LastName: event.state.LastName,
      EmailID: event.state.EmailID,
      DOB: event.state.DOB,
      Address: event.state.Address,
      Password: event.state.Password,
      Age: event.state.Age,
      ContactNumber: event.state.ContactNumber,
    });
  }
  handleSubmit(event) {
    event.preventDefault();
    if (this.handleValidation()) {
      try {
        event.preventDefault();
        event.persist();

        axios
          .post(
            "/sql/Login/Register.php",
            {
              authflag: 1,
              userId: this.state.Username,
              FirstName: this.state.FirstName,
              LastName: this.state.LastName,
              Email: this.state.EmailID,
              DOB: this.state.DOB,
              Address: this.state.Address,
              Password: this.state.Password,
              Age: this.state.Age,
              ContactNumber: this.state.ContactNumber,
            },
            options
          )
          .then((response) => {
            console.log(response);
            if (response.data.success) {
              if (response.data.userType == "ST") {
                this.props.navigate("/student");
              }
              if (response.data.userType == "BO") {
                this.props.navigate("/OwnerHome");
              }
              if (response.data.userType == "SO") {
                this.props.navigate("/SchoolAdmin");
              }
              if (response.data.userType == "SU") {
                this.props.navigate("/Superadminhome");
              }
            }

            return;
          });
      } catch (error) {
        throw error;
      }
    }
  }
  render() {
    return (
      <div>
        <AppBar position="static" alignitems="center" color="primary">
          <Toolbar>
            <Grid container justify="center" wrap="wrap">
              <Grid item>
                <Typography variant="h6">{BRAND_NAME}</Typography>
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>

        <Grid container spacing={0} justify="center" direction="row">
          <Grid item>
            <Grid
              container
              direction="column"
              justify="center"
              spacing={2}
              className="login-form"
            >
              <Paper
                variant="elevation"
                elevation={8}
                className="login-background"
              >
                <Grid item>
                  <Typography component="h1" variant="h5">
                    Register
                  </Typography>
                </Grid>
                <FormControl sx={{ m: 1, minWidth: 450 }}>
                  {/* <InputLabel id="demo-simple-select-autowidth-label">
                    Select User
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-autowidth-label"
                    id="demo-simple-select-autowidth"
                    value="SelectUser"
                    // onChange={handleChange}
                    autoWidth
                    label="Select User"
                  >
                    <MenuItem value={10}>Super Admin</MenuItem>
                    <MenuItem value={21}>School Admin</MenuItem>
                    <MenuItem value={22}>Student</MenuItem>
                    <MenuItem value={22}>Bussiness Owner </MenuItem>
                  </Select> */}
                </FormControl>
                <Grid item>
                  <form onSubmit={this.handleSubmit}>
                    <Grid container direction="column" spacing={2}>
                      <Grid item>
                        <TextField
                          label="User ID"
                          type="Username"
                          fullWidth
                          name="Username"
                          variant="outlined"
                          value={this.state.Username}
                          onChange={(event) =>
                            this.setState({
                              [event.target.name]: event.target.value,
                            })
                          }
                          required
                          autoFocus
                        />
                        <span style={{ color: "red" }}>
                          {this.state.errors["Username"]}
                        </span>
                      </Grid>
                      <Grid item>
                        <TextField
                          label="First Name"
                          type="FirstName"
                          fullWidth
                          name="FirstName"
                          variant="outlined"
                          value={this.state.FirstName}
                          onChange={(event) =>
                            this.setState({
                              [event.target.name]: event.target.value,
                            })
                          }
                          required
                        />
                        <span style={{ color: "red" }}>
                          {this.state.errors["FirstName"]}
                        </span>
                      </Grid>
                      <Grid item>
                        <TextField
                          label="Last Name"
                          type="LastName"
                          fullWidth
                          name="LastName"
                          variant="outlined"
                          value={this.state.LastName}
                          onChange={(event) =>
                            this.setState({
                              [event.target.name]: event.target.value,
                            })
                          }
                          required
                        />
                        <span style={{ color: "red" }}>
                          {this.state.errors["LastName"]}
                        </span>
                      </Grid>
                      <Grid item>
                        <TextField
                          label="Email ID"
                          type="EmailID"
                          fullWidth
                          name="EmailID"
                          variant="outlined"
                          value={this.state.EmailID}
                          onChange={(event) =>
                            this.setState({
                              [event.target.name]: event.target.value,
                            })
                          }
                          required
                        />
                        <span style={{ color: "red" }}>
                          {this.state.errors["EmailID"]}
                        </span>
                      </Grid>
                      <Grid item>
                        <TextField
                          label="Date of Birth"
                          type="DOB"
                          placeholder="mm/dd/yyyy"
                          fullWidth
                          name="DOB"
                          variant="outlined"
                          value={this.state.DOB}
                          onChange={(event) =>
                            this.setState({
                              [event.target.name]: event.target.value,
                            })
                          }
                          required
                        />
                        <span style={{ color: "red" }}>
                          {this.state.errors["DOB"]}
                        </span>
                      </Grid>
                      <Grid item>
                        <TextField
                          label="Address"
                          type="Address"
                          fullWidth
                          name="Address"
                          variant="outlined"
                          value={this.state.Address}
                          onChange={(event) =>
                            this.setState({
                              [event.target.name]: event.target.value,
                            })
                          }
                          required
                        />
                        <span style={{ color: "red" }}>
                          {this.state.errors["Address"]}
                        </span>
                      </Grid>
                      <Grid item>
                        <TextField
                          label="Password"
                          type="Password"
                          fullWidth
                          name="Password"
                          variant="outlined"
                          value={this.state.Password}
                          onChange={(event) =>
                            this.setState({
                              [event.target.name]: event.target.value,
                            })
                          }
                          required
                        />
                        <span style={{ color: "red" }}>
                          {this.state.errors["Password"]}
                        </span>
                      </Grid>
                      <Grid item>
                        <TextField
                          label="Age"
                          type="Age"
                          fullWidth
                          name="Age"
                          variant="outlined"
                          value={this.state.Age}
                          onChange={(event) =>
                            this.setState({
                              [event.target.name]: event.target.value,
                            })
                          }
                          required
                        />
                        <span style={{ color: "red" }}>
                          {this.state.errors["Age"]}
                        </span>
                      </Grid>
                      <Grid item>
                        <TextField
                          label="ContactNumber"
                          type="ContactNumber"
                          fullWidth
                          name="ContactNumber"
                          variant="outlined"
                          value={this.state.ContactNumber}
                          onChange={(event) =>
                            this.setState({
                              [event.target.name]: event.target.value,
                            })
                          }
                          required
                        />
                        <span style={{ color: "red" }}>
                          {this.state.errors["ContactNumber"]}
                        </span>
                      </Grid>
                      <Grid item>
                        <Button
                          variant="contained"
                          color="primary"
                          type="submit"
                          className="button-block"
                        >
                          Register
                        </Button>
                      </Grid>
                    </Grid>
                  </form>
                </Grid>
                <Grid item>
                  <Link href="/login" variant="body2">
                    Back to Login
                  </Link>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  }
}
export default withRouter(Login);
