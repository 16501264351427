import React from "react";
import {
  Button,
  TextField,
  Grid,
  Paper,
  AppBar,
  Typography,
  Toolbar,
  Link,
} from "@material-ui/core";
import axios from "axios";

const BRAND_NAME = "Mercado Escolar";

const options = {
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Content-Type": "application/json",
  },
};

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      SID: localStorage.getItem("userId"),
      FirstName: "",
      LastName: "",
      EmailID: "",
      Address: "",
      Card: "",
      Exp: "",
      Cvv: "",
      authflag: 1,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  handleChange(event) {
    this.setState({
      SID: event.state.SID,
      FirstName: event.state.FirstName,
      LastName: event.state.LastName,
      EmailID: event.state.EmailID,
      Address: event.state.Address,
      Card: event.state.Card,
      Exp: event.state.Exp,
      Cvv: event.state.Cvv,
    });
  }
  handleSubmit(event) {
    event.preventDefault();
    console.log(this.state.userId);

    try {
      event.preventDefault();
      event.persist();

      axios
        .post(
          "/sql/Student/Payment.php",
          {
            userId: this.state.SID,
            FirstName: this.state.FirstName,
            LastName: this.state.LastName,
            Email: this.state.EmailID,
            Address: this.state.Address,
            Card: this.state.Card,
            Expiration: this.state.Exp,
            Cvv: this.state.Cvv,
          },
          options
        )
        .then((response) => {
          console.log(response, "response");
          if (response.data.success) {
          }
          return;
        });
    } catch (error) {
      throw error;
    }
  }
  render() {
    if (localStorage.getItem("userType") == "ST") {
      return (
        <div>
          <AppBar position="static" alignitems="center" color="primary">
            <Toolbar>
              <Grid container justify="center" wrap="wrap">
                <Grid item>
                  <Typography variant="h6">{BRAND_NAME}</Typography>
                </Grid>
              </Grid>
            </Toolbar>
          </AppBar>
          <Grid container direction="column" spacing={2}>
            <Grid item>
              <Button
                href="/student"
                variant="contained"
                color="Secondary"
                type="submit"
                className="button-block"
              >
                Home
              </Button>
            </Grid>
          </Grid>
          <Grid container spacing={8} justify="center" direction="row">
            <Grid item>
              <Grid
                container
                direction="column"
                justify="center"
                spacing={2}
                className="login-form"
              >
                <Paper
                  variant="elevation"
                  elevation={8}
                  className="login-background"
                >
                  <Grid item>
                    <Typography component="h1" variant="h5">
                      Payment
                    </Typography>
                  </Grid>
                  <Grid item>
                    <form onSubmit={this.handleSubmit}>
                      <Grid container direction="column" spacing={2}>
                        <Grid item>
                          <TextField
                            label="Student ID"
                            type="SID"
                            fullWidth
                            name="SID"
                            variant="outlined"
                            value={this.state.SID}
                            disabled
                            onChange={(event) =>
                              this.setState({
                                [event.target.name]: event.target.value,
                              })
                            }
                            required
                            autoFocus
                          />
                        </Grid>
                        <Grid item>
                          <TextField
                            label="First Name"
                            type="FirstName"
                            fullWidth
                            name="FirstName"
                            variant="outlined"
                            value={this.state.FirstName}
                            onChange={(event) =>
                              this.setState({
                                [event.target.name]: event.target.value,
                              })
                            }
                            required
                          />
                        </Grid>
                        <Grid item>
                          <TextField
                            label="Last Name"
                            type="LastName"
                            fullWidth
                            name="LastName"
                            variant="outlined"
                            value={this.state.LastName}
                            onChange={(event) =>
                              this.setState({
                                [event.target.name]: event.target.value,
                              })
                            }
                            required
                          />
                        </Grid>
                        <Grid item>
                          <TextField
                            label="Email ID"
                            type="EmailID"
                            fullWidth
                            name="EmailID"
                            variant="outlined"
                            value={this.state.EmailID}
                            onChange={(event) =>
                              this.setState({
                                [event.target.name]: event.target.value,
                              })
                            }
                            required
                          />
                        </Grid>
                        <Grid item>
                          <TextField
                            label="Address"
                            type="Address"
                            fullWidth
                            name="Address"
                            variant="outlined"
                            value={this.state.Address}
                            onChange={(event) =>
                              this.setState({
                                [event.target.name]: event.target.value,
                              })
                            }
                            required
                          />
                        </Grid>
                        <Grid item>
                          <TextField
                            label="Card"
                            type="Card"
                            fullWidth
                            name="Card"
                            variant="outlined"
                            value={this.state.Card}
                            onChange={(event) =>
                              this.setState({
                                [event.target.name]: event.target.value,
                              })
                            }
                            required
                          />
                        </Grid>
                        <Grid item>
                          <TextField
                            label="Exp"
                            type="Exp"
                            fullWidth
                            name="Exp"
                            variant="outlined"
                            value={this.state.Exp}
                            onChange={(event) =>
                              this.setState({
                                [event.target.name]: event.target.value,
                              })
                            }
                            required
                          />
                        </Grid>
                        <Grid item>
                          <TextField
                            label="Cvv"
                            type="Cvv"
                            fullWidth
                            name="Cvv"
                            variant="outlined"
                            value={this.state.Cvv}
                            onChange={(event) =>
                              this.setState({
                                [event.target.name]: event.target.value,
                              })
                            }
                            required
                          />
                        </Grid>
                        <Grid item>
                          <Button
                            variant="contained"
                            color="Secondary"
                            type="submit"
                            className="button-block"
                          >
                            Submit
                          </Button>
                        </Grid>
                      </Grid>
                    </form>
                  </Grid>
                  {/* <Grid container direction="column" spacing={2}>
                    <Grid item>
                      <Button
                        href="/ManageStudent"
                        variant="contained"
                        color="Secondary"
                        type="submit"
                        className="button-block"
                      >
                        View Student
                      </Button>
                    </Grid>
                  </Grid> */}
                </Paper>
              </Grid>
            </Grid>
          </Grid>
        </div>
      );
    } else {
      if (localStorage.getItem("userType") == "BO") {
        window.location.href = "/OwnerHome";
      } else if (localStorage.getItem("userType") == "SO") {
        window.location.href = "/SchoolAdmin";
      } else if (localStorage.getItem("userType") == "SU") {
        window.location.href = "/Superadminhome";
      } else {
        localStorage.clear();
        window.location.href = "/login";
      }
    }
  }
}
export default Login;
