import React from "react";
import {
  Button,
  TextField,
  Grid,
  Paper,
  AppBar,
  Typography,
  Toolbar,
  Link,
} from "@material-ui/core";
import axios from "axios";

const BRAND_NAME = "Mercado Escolar";

const options = {
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Content-Type": "application/json",
  },
};

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      AdID: "",
      authflag: 1,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  handleChange(event) {
    this.setState({
      AdID: event.state.AdID,
    });
  }
  handleSubmit(event) {
    event.preventDefault();
    console.log(this.state);
    try {
      event.preventDefault();
      event.persist();

      axios
        .post(
          "/sql/SuperAdmin/DeleteStudent.php",
          {
            authflag: 1,
            AdID: this.state.AdID,
          },
          options
        )
        .then((response) => {
          console.log(response, "response");
          return;
        });
    } catch (error) {
      throw error;
    }
  }
  render() {
    if (localStorage.getItem("userType") == "BO") {
      return (
        <div>
          <AppBar position="static" alignitems="center" color="primary">
            <Toolbar>
              <Grid container justify="center" wrap="wrap">
                <Grid item>
                  <Typography variant="h6">{BRAND_NAME}</Typography>
                </Grid>
              </Grid>
            </Toolbar>
          </AppBar>
          <Grid container spacing={0} justify="center" direction="row">
            <Grid item>
              <Grid
                container
                direction="column"
                justify="center"
                spacing={2}
                className="login-form"
              >
                <Paper
                  variant="elevation"
                  elevation={8}
                  className="login-background"
                >
                  <Grid item>
                    <Typography component="h1" variant="h5">
                      Delete Product
                    </Typography>
                  </Grid>
                  <Grid item>
                    <form onSubmit={this.handleSubmit}>
                      <Grid container direction="column" spacing={2}>
                        <Grid item>
                          <TextField
                            label="Advertisement ID"
                            type="AdID"
                            fullWidth
                            name="AdID"
                            variant="outlined"
                            value={this.state.AdID}
                            onChange={(event) =>
                              this.setState({
                                [event.target.name]: event.target.value,
                              })
                            }
                            required
                            autoFocus
                          />
                        </Grid>
                        <Grid item>
                          <Button
                            variant="contained"
                            color="primary"
                            type="submit"
                            className="button-block"
                          >
                            Delete?
                          </Button>
                        </Grid>
                      </Grid>
                    </form>
                  </Grid>
                  <Grid item>
                    <Link href="/OwnerHome" variant="body2">
                      Home
                    </Link>
                  </Grid>
                </Paper>
              </Grid>
            </Grid>
          </Grid>
        </div>
      );
    } else {
      if (localStorage.getItem("userType") == "SU") {
        window.location.href = "/Superadminhome";
      } else if (localStorage.getItem("userType") == "SO") {
        window.location.href = "/SchoolAdmin";
      } else if (localStorage.getItem("userType") == "ST") {
        window.location.href = "/student";
      } else {
        localStorage.clear();
        window.location.href = "/login";
      }
    }
  }
}
export default Login;
