import React from "react";
import {
  Button,
  TextField,
  Grid,
  Paper,
  AppBar,
  Typography,
  Toolbar,
  Link,
} from "@material-ui/core";

const BRAND_NAME = "Mercado Escolar";

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      Name: "",
      Email: "",
      Message: "",
      authflag: 1,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  handleChange(event) {
    this.setState({
      Name: event.state.Name,
      Email: event.state.Email,
      Message: event.state.Message,
    });
  }
  handleSubmit(event) {
    event.preventDefault();
    if (this.state.Name == "admin@littech.in" && this.state.Email == "secret") {
      this.props.history.push("/home");
    } else {
      alert("Incorrect Credntials!");
    }
  }
  render() {
    return (
      <div>
        <AppBar position="static" alignitems="center" color="primary">
          <Toolbar>
            <Grid container justify="center" wrap="wrap">
              <Grid item>
                <Typography variant="h6">{BRAND_NAME}</Typography>
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
        <Grid container spacing={0} justify="center" direction="row">
          <Grid item>
            <Grid
              container
              direction="column"
              justify="center"
              spacing={0}
              className="login-form"
            >
              <Paper
                variant="elevation"
                elevation={8}
                className="login-background"
              >
                <Grid item>
                  <Typography component="h1" variant="h5">
                    Contact Us
                  </Typography>
                </Grid>
                <Grid item>
                  <form onSubmit={this.handleSubmit}>
                    <Grid container direction="column" spacing={2}>
                      <Grid item>
                        <TextField
                          label="Name"
                          type="Name"
                          placeholder="Enter Your Name"
                          fullWidth
                          name="Name"
                          variant="outlined"
                          value={this.state.Name}
                          onChange={(event) =>
                            this.setState({
                              [event.target.name]: event.target.value,
                            })
                          }
                          required
                          autoFocus
                        />
                      </Grid>
                      <Grid item>
                        <TextField
                          label="Email"
                          type="Email"
                          placeholder="Enter Your Email"
                          fullWidth
                          name="Email"
                          variant="outlined"
                          value={this.state.Email}
                          onChange={(event) =>
                            this.setState({
                              [event.target.name]: event.target.value,
                            })
                          }
                          required
                        />
                      </Grid>
                      <Grid item>
                        <TextField
                          label="Message"
                          type="Message"
                          placeholder="Type your Message"
                          fullWidth
                          name="Message"
                          variant="outlined"
                          value={this.state.Message}
                          onChange={(event) =>
                            this.setState({
                              [event.target.name]: event.target.value,
                            })
                          }
                          required
                        />
                      </Grid>

                      <Grid item>
                        <Button
                          variant="contained"
                          color="primary"
                          type="submit"
                          className="button-block"
                        >
                          Submit
                        </Button>
                      </Grid>
                    </Grid>
                  </form>
                </Grid>
                <Grid item>
                  <Link href="/" variant="body2">
                    Home
                  </Link>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  }
}
export default Login;
