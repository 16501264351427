import React from "react";
import {
  Button,
  TextField,
  Grid,
  Paper,
  AppBar,
  Typography,
  Toolbar,
  Link,
} from "@material-ui/core";
import axios from "axios";
import FormControl from "@mui/material/FormControl";
import { withRouter } from "./withRouter";

const BRAND_NAME = "Mercado Escolar";

const options = {
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Content-Type": "application/json",
  },
};

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      Username: "",
      Password: "",
      authflag: 1,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(event) {
    this.setState({
      Username: event.state.Username,
      Password: event.state.Password,
    });
  }
  handleSubmit(event) {
    event.preventDefault();
    try {
      event.preventDefault();
      event.persist();

      axios
        .post(
          "/sql/Login/login.php",
          {
            authflag: 1,
            userId: this.state.Username,
            password: this.state.Password,
          },
          options
        )
        .then((response) => {
          //   console.log(response);
          //   var resData = response.data;
          //   console.log(response);
          //   var res = JSON.stringify(resData);
          //   var temp = resData.replace(/\\/g, "");
          //   temp = temp.replace(/(\r\n|\n|\r)/gm, "");
          //   console.log(temp);
          //   console.log(response.data.userlist.user);
          console.log(response);
          if (response.data.success) {
            console.log("USERID:", response.data.userId);
            localStorage.setItem("userId", response.data.userId);
            localStorage.setItem(
              "userInfo",
              JSON.stringify(response.data.userlist.userdata[0])
            );
            localStorage.setItem("userType", response.data.userType);
            if (response.data.userType == "ST") {
              this.props.navigate("/student");
            }
            if (response.data.userType == "BO") {
              this.props.navigate("/OwnerHome");
            }
            if (response.data.userType == "SO") {
              this.props.navigate("/SchoolAdmin");
            }
            if (response.data.userType == "SU") {
              this.props.navigate("/Superadminhome");
            }
          }

          return;
        });
    } catch (error) {
      throw error;
    }
  }
  render() {
    return (
      <div>
        <AppBar position="static" alignitems="center" color="primary">
          <Toolbar>
            <Grid container justify="center" wrap="wrap">
              <Grid item>
                <Typography variant="h6">{BRAND_NAME}</Typography>
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
        <Grid container spacing={0} justify="center" direction="row">
          <Grid item>
            <Grid
              container
              direction="column"
              justify="center"
              spacing={2}
              className="login-form"
            >
              <Paper
                variant="elevation"
                elevation={8}
                className="login-background"
              >
                <Grid item>
                  <Typography component="h1" variant="h5">
                    Sign In
                  </Typography>
                </Grid>
                <FormControl sx={{ m: 1, minWidth: 450 }}>
                  <Grid item>
                    <form onSubmit={this.handleSubmit}>
                      <Grid container direction="column" spacing={2}>
                        <Grid item>
                          <TextField
                            label="User ID"
                            type="Username"
                            fullWidth
                            name="Username"
                            variant="outlined"
                            value={this.state.Username}
                            onChange={(event) =>
                              this.setState({
                                [event.target.name]: event.target.value,
                              })
                            }
                            required
                            autoFocus
                          />
                        </Grid>
                        <Grid item>
                          <TextField
                            label="Password"
                            type="Password"
                            fullWidth
                            name="Password"
                            variant="outlined"
                            value={this.state.Password}
                            onChange={(event) =>
                              this.setState({
                                [event.target.name]: event.target.value,
                              })
                            }
                            required
                            autoFocus
                          />
                        </Grid>
                        <Grid item>
                          <Button
                            variant="contained"
                            color="secondary"
                            type="submit"
                            className="button-block"
                          >
                            Login
                          </Button>
                        </Grid>
                      </Grid>
                    </form>
                  </Grid>
                </FormControl>
                <Grid container direction="column" spacing={2}>
                  <Grid item>
                    <Button
                      href="/forget"
                      variant="contained"
                      color="Secondary"
                      type="submit"
                      className="button-block"
                    >
                      Forget?
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      href="/Register"
                      variant="contained"
                      color="Secondary"
                      type="submit"
                      className="button-block"
                    >
                      Register
                    </Button>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  }
}
export default withRouter(Login);
