import React from "react";
import {
  Button,
  TextField,
  Grid,
  Paper,
  AppBar,
  Typography,
  Toolbar,
  Link,
} from "@material-ui/core";
import FormControl from "@mui/material/FormControl";
import axios from "axios";

const BRAND_NAME = "Mercado Escolar";

const options = {
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Content-Type": "application/json",
  },
};

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ClubID: "",
      ClubOwnerID: localStorage.getItem("userId"),
      ClubName: "",
      Description: "",
      NumberofStudents: "",
      authflag: 1,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  handleChange(event) {
    this.setState({
      ClubID: event.state.ClubID,
      ClubOwnerID: event.state.ClubOwnerID,
      ClubName: event.state.ClubName,
      Description: event.state.Description,
      NumberofStudents: event.state.NumberofStudents,
    });
  }
  handleSubmit(event) {
    event.preventDefault();
    console.log(this.state);
    try {
      event.preventDefault();
      event.persist();

      axios
        .post(
          "/sql/Student/AddClub.php",
          {
            authflag: 1,
            ClubID: this.state.ClubID,
            ClubOwnerID: this.state.ClubOwnerID,
            ClubName: this.state.ClubName,
            Description: this.state.Description,
            NumberofStudents: this.state.NumberofStudents,
          },
          options
        )
        .then((response) => {
          console.log(response, "response");
          window.location.href = "/Club";
          return;
        });
    } catch (error) {
      throw error;
    }
  }
  render() {
    if (localStorage.getItem("userType") == "ST") {
      return (
        <div>
          <AppBar position="static" alignitems="center" color="primary">
            <Toolbar>
              <Grid container justify="center" wrap="wrap">
                <Grid item>
                  <Typography variant="h6">{BRAND_NAME}</Typography>
                </Grid>
              </Grid>
            </Toolbar>
          </AppBar>
          <Grid container spacing={0} justify="center" direction="row">
            <Grid item>
              <Grid
                container
                direction="column"
                justify="center"
                spacing={2}
                className="login-form"
              >
                <Paper
                  variant="elevation"
                  elevation={8}
                  className="login-background"
                >
                  <Grid item>
                    <Typography component="h1" variant="h5">
                      Create New Club
                    </Typography>
                  </Grid>
                  <FormControl sx={{ m: 1, minWidth: 450 }}>
                    <Grid item>
                      <form onSubmit={this.handleSubmit}>
                        <Grid container direction="column" spacing={2}>
                          <Grid item>
                            <TextField
                              label="Club ID"
                              type="ClubID"
                              fullWidth
                              name="ClubID"
                              variant="outlined"
                              value={this.state.ClubID}
                              onChange={(event) =>
                                this.setState({
                                  [event.target.name]: event.target.value,
                                })
                              }
                              required
                              autoFocus
                            />
                          </Grid>
                          <Grid item>
                            <TextField
                              label="ClubOwner ID"
                              type="ClubOwnerID"
                              fullWidth
                              name="ClubOwnerID"
                              variant="outlined"
                              value={this.state.ClubOwnerID}
                              disabled
                              onChange={(event) =>
                                this.setState({
                                  [event.target.name]: event.target.value,
                                })
                              }
                              required
                            />
                          </Grid>
                          <Grid item>
                            <TextField
                              label="Club Name"
                              type="ClubName"
                              fullWidth
                              name="ClubName"
                              variant="outlined"
                              value={this.state.ClubName}
                              onChange={(event) =>
                                this.setState({
                                  [event.target.name]: event.target.value,
                                })
                              }
                              required
                            />
                          </Grid>
                          <Grid item>
                            <TextField
                              label="Description"
                              type="Description"
                              fullWidth
                              name="Description"
                              variant="outlined"
                              value={this.state.Description}
                              onChange={(event) =>
                                this.setState({
                                  [event.target.name]: event.target.value,
                                })
                              }
                              required
                            />
                          </Grid>
                          <Grid item>
                            <TextField
                              label="Number of Students"
                              type="NumberofStudents"
                              fullWidth
                              name="NumberofStudents"
                              variant="outlined"
                              value={this.state.NumberofStudents}
                              onChange={(event) =>
                                this.setState({
                                  [event.target.name]: event.target.value,
                                })
                              }
                              required
                            />
                          </Grid>
                          <Grid item>
                            <Button
                              variant="contained"
                              color="primary"
                              type="submit"
                              className="button-block"
                            >
                              Submit
                            </Button>
                          </Grid>
                        </Grid>
                      </form>
                    </Grid>
                  </FormControl>
                  <Grid container direction="column" spacing={2}>
                    <Grid item>
                      <Button
                        href="/Club"
                        variant="contained"
                        color="Secondary"
                        type="submit"
                        className="button-block"
                      >
                        View Clubs
                      </Button>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            </Grid>
          </Grid>
        </div>
      );
    } else {
      if (localStorage.getItem("userType") == "BO") {
        window.location.href = "/OwnerHome";
      } else if (localStorage.getItem("userType") == "SO") {
        window.location.href = "/SchoolAdmin";
      } else if (localStorage.getItem("userType") == "SU") {
        window.location.href = "/Superadminhome";
      } else {
        localStorage.clear();
        window.location.href = "/login";
      }
    }
  }
}
export default Login;
