import React from "react";
import {
  Button,
  TextField,
  Grid,
  Paper,
  AppBar,
  Typography,
  Toolbar,
  Link,
} from "@material-ui/core";
import FormControl from "@mui/material/FormControl";
import axios from "axios";
import { json } from "react-router-dom";

const BRAND_NAME = "Mercado Escolar";

const options = {
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Content-Type": "application/json",
  },
};

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      SID: localStorage.getItem("userId"),
      FirstName: localStorage.getItem(""),
      LastName: "",
      EmailID: "",
      DOB: "",
      Address: "",
      Password: "",
      Age: "",
      ContactNumber: "",
      authflag: 1,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  handleChange(event) {
    this.setState({
      SID: event.state.SID,
      FirstName: event.state.FirstName,
      LastName: event.state.LastName,
      EmailID: event.state.EmailID,
      DOB: event.state.DOB,
      Address: event.state.Address,
      Password: event.state.Password,
      Age: event.state.Age,
      ContactNumber: event.state.ContactNumber,
    });
  }

  componentDidMount() {
    console.log(localStorage.getItem("userInfo"));
    let userData = JSON.parse(localStorage.getItem("userInfo"));
    this.setState({
      FirstName: userData.FirstName,
      LastName: userData.LastName,
      Email: userData.Email,
      DOB: userData.DOB,
      Address: userData.Address,
      Password: userData.Password,
      Age: userData.Age,
      ContactNumber: userData.ContactNumber,
    });
  }
  handleSubmit(event) {
    event.preventDefault();
    console.log(this.state);
    try {
      event.preventDefault();
      event.persist();

      axios
        .post(
          "/sql/SuperAdmin/UpdateProfile.php",
          {
            authflag: 1,
            SID: this.state.SID,
            FirstName: this.state.FirstName,
            LastName: this.state.LastName,
            Email: this.state.EmailID,
            DOB: this.state.DOB,
            Address: this.state.Address,
            Password: this.state.Password,
            Age: this.state.Age,
            ContactNumber: this.state.ContactNumber,
          },
          options
        )
        .then((response) => {
          console.log(response, "response");
          return;
        });
    } catch (error) {
      throw error;
    }
  }
  render() {
    if (localStorage.getItem("userType") == "ST") {
      return (
        <div>
          <AppBar position="static" alignitems="center" color="primary">
            <Toolbar>
              <Grid container justify="center" wrap="wrap">
                <Grid item>
                  <Typography variant="h6">{BRAND_NAME}</Typography>
                </Grid>
              </Grid>
            </Toolbar>
          </AppBar>
          <Grid container direction="column" spacing={2}>
            <Grid item>
              <Button
                href="/student"
                variant="contained"
                color="Secondary"
                type="submit"
                className="button-block"
              >
                Student Home
              </Button>
            </Grid>
          </Grid>
          <Grid container spacing={8} justify="center" direction="row">
            <Grid item>
              <Grid
                container
                direction="column"
                justify="center"
                spacing={2}
                className="login-form"
              >
                <Paper
                  variant="elevation"
                  elevation={8}
                  className="login-background"
                >
                  <Grid item>
                    <Typography component="h1" variant="h5">
                      Update Profile
                    </Typography>
                  </Grid>
                  <FormControl sx={{ m: 1, minWidth: 450 }}>
                    <Grid item>
                      <form onSubmit={this.handleSubmit}>
                        <Grid container direction="column" spacing={2}>
                          <Grid item>
                            <TextField
                              label="Student ID"
                              type="SID"
                              fullWidth
                              name="SID"
                              variant="outlined"
                              value={this.state.SID}
                              disabled
                              onChange={(event) =>
                                this.setState({
                                  [event.target.name]: event.target.value,
                                })
                              }
                              required
                              autoFocus
                            />
                          </Grid>
                          <Grid item>
                            <TextField
                              label="First Name"
                              type="FirstName"
                              fullWidth
                              name="FirstName"
                              variant="outlined"
                              value={this.state.FirstName}
                              onChange={(event) =>
                                this.setState({
                                  [event.target.name]: event.target.value,
                                })
                              }
                              required
                            />
                          </Grid>
                          <Grid item>
                            <TextField
                              label="Last Name"
                              type="LastName"
                              fullWidth
                              name="LastName"
                              variant="outlined"
                              value={this.state.LastName}
                              onChange={(event) =>
                                this.setState({
                                  [event.target.name]: event.target.value,
                                })
                              }
                              required
                            />
                          </Grid>
                          <Grid item>
                            <TextField
                              label="Email ID"
                              type="EmailID"
                              fullWidth
                              name="EmailID"
                              variant="outlined"
                              value={this.state.EmailID}
                              onChange={(event) =>
                                this.setState({
                                  [event.target.name]: event.target.value,
                                })
                              }
                              required
                            />
                          </Grid>
                          <Grid item>
                            <TextField
                              label="Date of Birth"
                              type="DOB"
                              placeholder="mm/dd/yyyy"
                              fullWidth
                              name="DOB"
                              variant="outlined"
                              value={this.state.DOB}
                              onChange={(event) =>
                                this.setState({
                                  [event.target.name]: event.target.value,
                                })
                              }
                              required
                            />
                          </Grid>
                          <Grid item>
                            <TextField
                              label="Address"
                              type="Address"
                              fullWidth
                              name="Address"
                              variant="outlined"
                              value={this.state.Address}
                              onChange={(event) =>
                                this.setState({
                                  [event.target.name]: event.target.value,
                                })
                              }
                              required
                            />
                          </Grid>
                          <Grid item>
                            <TextField
                              label="Password"
                              type="Password"
                              fullWidth
                              name="Password"
                              variant="outlined"
                              value={this.state.Password}
                              onChange={(event) =>
                                this.setState({
                                  [event.target.name]: event.target.value,
                                })
                              }
                              required
                            />
                          </Grid>
                          <Grid item>
                            <TextField
                              label="Age"
                              type="Age"
                              fullWidth
                              name="Age"
                              variant="outlined"
                              value={this.state.Age}
                              onChange={(event) =>
                                this.setState({
                                  [event.target.name]: event.target.value,
                                })
                              }
                              required
                            />
                          </Grid>
                          <Grid item>
                            <TextField
                              label="ContactNumber"
                              type="ContactNumber"
                              fullWidth
                              name="ContactNumber"
                              variant="outlined"
                              value={this.state.ContactNumber}
                              onChange={(event) =>
                                this.setState({
                                  [event.target.name]: event.target.value,
                                })
                              }
                              required
                            />
                          </Grid>
                          <Grid item>
                            <Button
                              variant="contained"
                              color="Secondary"
                              type="submit"
                              className="button-block"
                            >
                              Update?
                            </Button>
                          </Grid>
                        </Grid>
                      </form>
                    </Grid>
                  </FormControl>
                  <Grid container direction="column" spacing={2}></Grid>
                </Paper>
              </Grid>
            </Grid>
          </Grid>
        </div>
      );
    } else {
      if (localStorage.getItem("userType") == "BO") {
        window.location.href = "/OwnerHome";
      } else if (localStorage.getItem("userType") == "SO") {
        window.location.href = "/SchoolAdmin";
      } else if (localStorage.getItem("userType") == "SU") {
        window.location.href = "/Superadminhome";
      } else {
        localStorage.clear();
        window.location.href = "/login";
      }
    }
  }
}
export default Login;
