import React from "react";
import {
  Button,
  TextField,
  Grid,
  Paper,
  AppBar,
  Typography,
  Toolbar,
  Link,
} from "@material-ui/core";
import axios from "axios";
import FormControl from "@mui/material/FormControl";
const BRAND_NAME = "Mercado Escolar";

const options = {
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Content-Type": "application/json",
  },
};

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      AdID: "",
      BoID: localStorage.getItem("userId"),
      Description: "",
      ContactNumber: "",
      authflag: 1,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  handleChange(event) {
    this.setState({
      AdID: event.state.AdID,
      BoID: event.state.BoID,
      Description: event.state.Description,
      ContactNumber: event.state.ContactNumber,
    });
  }
  handleSubmit(event) {
    event.preventDefault();
    console.log(this.state);
    try {
      event.preventDefault();
      event.persist();

      axios
        .post(
          "/sql/BusinessOwner/AddAds.php",
          {
            AdID: this.state.AdID,
            CreaterID: this.state.BoID,
            Description: this.state.Description,
            ContactNumber: this.state.ContactNumber,
          },
          options
        )
        .then((response) => {
          console.log(response.data.success, "response");
          if (response.data.success) {
          }
          return;
        });
    } catch (error) {
      throw error;
    }
  }
  render() {
    if (localStorage.getItem("userType") == "BO") {
      return (
        <div>
          <AppBar position="static" alignitems="center" color="primary">
            <Toolbar>
              <Grid container justify="center" wrap="wrap">
                <Grid item>
                  <Typography variant="h6">{BRAND_NAME}</Typography>
                </Grid>
              </Grid>
            </Toolbar>
          </AppBar>
          <Grid container spacing={0} justify="center" direction="row">
            <Grid item>
              <Grid
                container
                direction="column"
                justify="center"
                spacing={2}
                className="login-form"
              >
                <Paper
                  variant="elevation"
                  elevation={8}
                  className="login-background"
                >
                  <Grid item>
                    <Typography component="h1" variant="h5">
                      Add New Product
                    </Typography>
                  </Grid>
                  <FormControl sx={{ m: 1, minWidth: 450 }}>
                    <Grid item>
                      <form onSubmit={this.handleSubmit}>
                        <Grid container direction="column" spacing={2}>
                          <Grid item>
                            <TextField
                              label="Advertisement ID"
                              type="AdID"
                              fullWidth
                              name="AdID"
                              variant="outlined"
                              value={this.state.AdID}
                              onChange={(event) =>
                                this.setState({
                                  [event.target.name]: event.target.value,
                                })
                              }
                              required
                              autoFocus
                            />
                          </Grid>
                          <Grid item>
                            <TextField
                              label="Creater ID"
                              type="CreaterID"
                              fullWidth
                              name="CreaterID"
                              variant="outlined"
                              value={this.state.BoID}
                              disabled
                              onChange={(event) =>
                                this.setState({
                                  [event.target.name]: event.target.value,
                                })
                              }
                              required
                            />
                          </Grid>
                          <Grid item>
                            <TextField
                              label="Description"
                              type="Description"
                              fullWidth
                              name="Description"
                              variant="outlined"
                              rows={4}
                              maxRows={4}
                              value={this.state.Description}
                              onChange={(event) =>
                                this.setState({
                                  [event.target.name]: event.target.value,
                                })
                              }
                              required
                            />
                          </Grid>
                          <Grid item>
                            <TextField
                              label="Contact Number"
                              type="ContactNumber"
                              fullWidth
                              name="ContactNumber"
                              variant="outlined"
                              value={this.state.ContactNumber}
                              onChange={(event) =>
                                this.setState({
                                  [event.target.name]: event.target.value,
                                })
                              }
                              required
                            />
                          </Grid>
                          <Grid item>
                            <Button
                              variant="contained"
                              color="Secondary"
                              type="submit"
                              className="button-block"
                            >
                              Add?
                            </Button>
                          </Grid>
                        </Grid>
                      </form>
                    </Grid>
                  </FormControl>
                  <Grid item>
                    <Button
                      href="/ManageAds"
                      variant="contained"
                      color="Secondary"
                      type="submit"
                      className="button-block"
                    >
                      View Advertisement
                    </Button>
                  </Grid>
                </Paper>
              </Grid>
            </Grid>
          </Grid>
        </div>
      );
    } else {
      if (localStorage.getItem("userType") == "SU") {
        window.location.href = "/Superadminhome";
      } else if (localStorage.getItem("userType") == "SO") {
        window.location.href = "/SchoolAdmin";
      } else if (localStorage.getItem("userType") == "ST") {
        window.location.href = "/student";
      } else {
        localStorage.clear();
        window.location.href = "/login";
      }
    }
  }
}
export default Login;
